import React from "react";
import Task from "./Task";
import Utils from './Utils'
import './CalendarRow.scss'

function CalendarRow({date, dayCount, events, dueTillDone, inclTimeEstimates}: {date: Date, dayCount: number, events: any[], dueTillDone : boolean, inclTimeEstimates: boolean}) {

    const firstDateStr = Utils.getDateStr(date);
    const todayStr = Utils.getDateStr(new Date());

    const dayTitles:any = [];
    const dayBlocks:any = [];
    const dayTitleDate = new Date();
    dayTitleDate.setTime(date.getTime());
    for(let i=0; i<dayCount;i++){
        const thisDayStr = Utils.getDateStr(dayTitleDate);
        dayTitles.push(<div className={'dayTitle ' + (todayStr === thisDayStr ? 'today' : '')} key={thisDayStr}><div className="dayTitleText"><div className="dayName">{Utils.getDayNameShort(dayTitleDate.getDay())}</div>{dayTitleDate.getDate()}</div></div>)
        dayBlocks.push(<div className={'dayBlock ' + (todayStr === thisDayStr ? 'today' : '')} key={thisDayStr}></div>);

        dayTitleDate.setDate(dayTitleDate.getDate()+1);
    }

    return (
        <div className="calendarRow">
            <div className="dayTitles">{dayTitles}</div>
            <div className="rowEvents">
                <div className="dayBlocks">
                    {dayBlocks}
                </div>
                {events.map(function(e) {
                    const ev : Task = e[0];

                    const evDue = new Date();
                    evDue.setTime(ev.due_date);
                    const daysAfterDue = dueTillDone ? Math.max(0, Utils.getDaysBetweenDates(Utils.getDateStr(evDue), todayStr)) : 0;

                    const dueRegionLength = (ev.time_estimate && inclTimeEstimates ? Math.ceil(ev.time_estimate/1000/3600/8) : 1) + daysAfterDue;

                    const startOffset = Math.abs(Math.min(0, Utils.getDaysBetweenDates(firstDateStr, e[1])));
                    const rowStartOffset = Math.max(0, Utils.getDaysBetweenDates(firstDateStr, e[1]));
                    const rowDuration = Math.min(dayCount-rowStartOffset, Utils.getDaysBetweenDates(firstDateStr > e[1] ? firstDateStr : e[1], e[2])+1);
                    const duration = Utils.getDaysBetweenDates(e[1], e[2])+1;
                    const durationTillDue = duration - dueRegionLength;
                    const tailDuration = duration - startOffset - rowDuration;
                    const tailWithDueDuration = durationTillDue - startOffset - rowDuration;

                    const percPerDay = 100 / (rowDuration);

                    let posStart = -1 * startOffset * percPerDay,
                        posEnd = 100 + tailWithDueDuration * percPerDay,
                        posMid = (posStart + posEnd) / 2;

                    const solidColorAtEnd = dueRegionLength > 0 ?
                        `, #f00 ${posEnd}%` :
                        '';

                    const eventStyle = {
                        '--event-left-slot': rowStartOffset,
                        '--event-row-slots': rowDuration,
                        'background' : `linear-gradient(90deg, #0f0 ${posStart}%, yellow ${posMid}% , #ff6100 ${posEnd}%${solidColorAtEnd})`
                    } as React.CSSProperties;
                    return <a href={ev.url} target="_blank" className={'event ' + (startOffset===0?' start ' : '') + (tailDuration===0 ? ' end ' : '')} style={eventStyle} key={ev.id}>
                        <div className="nameTrack"><div className="eventName">{ev.name}</div></div>
                        <span className={'afterDue ' + (daysAfterDue===1?'single':'')}>{Math.floor(daysAfterDue) || ''}</span>
                    </a> //+ ' / ' + percPerDay + ' / ' + posStart + ' / '  + posMid + ' / ' + posEnd
                })}
            </div>
        </div>
    )
}

export default CalendarRow;