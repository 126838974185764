import React, {useState} from 'react';
import "./Checkbox.scss";
import HelpMouseMessage from "./HelpMouseMessage";
import {createPortal} from "react-dom";

function Checkbox({title, value, helpHtml, callback} : {title: string, value : boolean, helpHtml : JSX.Element, callback : any}) {

    const [ helpShow, setHelpShow ] = useState(false);

    const helpMsg = (
        <div>
            <div className="settingsHelpTitle">{title}</div>
            <div>{helpHtml}</div>
        </div>
    );

    return (
        <div className="setting">
            <span className={'checkbox ' + (value?'checked' : '')} onClick={callback.bind(undefined, !value)}>{title}</span>
            <span className="settingHelp" onMouseEnter={setHelpShow.bind(undefined, true)} onMouseLeave={setHelpShow.bind(undefined, false)} >❓</span>
            {helpShow ? createPortal(<HelpMouseMessage html={helpMsg} />, document.body) : ''}
        </div>
    );
}

export default Checkbox;
