
function Utils(){}

Utils.leadingZeroes = function (input:string,digits:number){
    var length = input.toString().length;
    for (let i=length;i<digits;i++){
        input = '0'+input;
    }
    return input;
}

Utils.getDateStr = function (date: Date) {
    return date.getFullYear() + '-' + Utils.leadingZeroes((date.getMonth()+1).toString(), 2) + '-' + Utils.leadingZeroes(date.getDate().toString(), 2);
}

Utils.getDaysBetweenDates = function (ymd1: string, ymd2: string) {
    const d1 = new Date(ymd1);
    const d2 = new Date(ymd2);
    d1.setHours(0,0,0);
    d2.setHours(0,0,0);
    return (d2.getTime() - d1.getTime())/1000/3600/24;
}

Utils.getDayNameShort = function (dayIndex: number) {
    return ['sun', 'mon','tue', 'wed', 'thu', 'fri', 'sat'][dayIndex];
}

Utils.storePreference = function (key : string, value : any) {
    if (window.localStorage) {
        window.localStorage.setItem('SETTINGS_'+key, JSON.stringify(value));
    }
}
Utils.getPreference = function (key : string, fallback : any) {
    const realkey = 'SETTINGS_'+key;
    if (window.localStorage && window.localStorage.getItem(realkey)) {
        return window.localStorage.getItem(realkey);
    }
    return fallback ?? undefined;
}


export default Utils;