import React, {CSSProperties, useEffect, useState} from "react";
import './Calendar.scss';
import Event from "./Event";
import Utils from "./Utils";
import CalendarRow from "./CalendarRow";
import { useMediaQuery } from 'react-responsive'


function Calendar({year, month, events, dueTillDone, inclTimeEstimates}: {year: number, month: number, events: Record<string, Event>, dueTillDone : boolean, inclTimeEstimates : boolean}) {

    const [ curYear , setYear ] = useState(year);
    const [ curMonth , setMonth ] = useState(month);
    const [ viewType, setViewType ] = useState(Calendar.VIEW_MONTH);
    let [ customDaysPerRow, setCustomDaysPerRow ] = useState(2);

    const firstOfMonthDate = new Date(curYear, curMonth-1, 1);
    const nowDate = new Date();
    nowDate.setHours(0,0,0);


    function prevMonth() {
        if (curMonth===1){ setYear(curYear-1); }
        setMonth(curMonth === 1 ? 12 : curMonth-1);
    }

    function nextMonth() {
        if (curMonth===12){ setYear(curYear+1); }
        setMonth(curMonth === 12 ? 1 : curMonth+1);
    }

    function showThisMonth() {
        const now = new Date();
        setMonth(now.getMonth()+1);
        setYear(now.getFullYear());
    }

    let daysCount : number, rowCount : number, daysPerRow:number;

    const dateStart = new Date();

    const dayFirst = (firstOfMonthDate.getDay()+6)%7;
    const previousMonday = new Date(firstOfMonthDate.getFullYear(), firstOfMonthDate.getMonth(), firstOfMonthDate.getDate());
    previousMonday.setDate(previousMonday.getDate() - dayFirst);

    switch (viewType) {
        default:
        case Calendar.VIEW_WEEK:
            daysCount = 7;
            rowCount = 5;
            dateStart.setTime(previousMonday.getTime());
            break;
        case Calendar.VIEW_MONTH_FROM_NOW:
            daysCount = 31;
            rowCount = 1;
            dateStart.setTime(nowDate.getTime());
            break;
        case Calendar.VIEW_MONTH:
            daysCount = (new Date(curYear, curMonth,0)).getDate();
            rowCount = 1;
            dateStart.setTime(firstOfMonthDate.getTime());
            break;
    }

    customDaysPerRow = Math.min(daysCount,Math.max(1,customDaysPerRow));


    const under960 = useMediaQuery({query : '(max-width: 960px)'});
    daysPerRow = under960 ? customDaysPerRow : daysCount;


    const rows: any[] = [];
    const dateRow = new Date();
    dateRow.setTime(dateStart.getTime());

    for (let d=0; d< rowCount; d++) {
        const dateFrom = new Date();
        dateFrom.setTime(dateRow.getTime());
        dateRow.setDate(dateRow.getDate()+6);
        rows.push({'date' : dateFrom, 'dateFrom' : Utils.getDateStr(dateFrom), 'dateTo' : Utils.getDateStr(dateRow), 'events':[]});

        dateRow.setDate(dateRow.getDate()+1);
    }

    for (let id in events){
        const e : Event = events[id];
        for (let r = 0; r < rows.length; r++) {
            //console.log(rows[r]['dateFrom'], rows[r]['dateTo'], startDateStr, dueDateStr);
            if (e.startDateStr <= rows[r]['dateTo'] && rows[r]['dateFrom'] <= e.dueDateStr){
                rows[r]["events"].push([e, e.startDateStr, e.dueDateStr]);
                //console.log('insert');
            }
        }
    }

    const ranges : any = {};
    ranges[Calendar.VIEW_WEEK] = 'Week';
    ranges[Calendar.VIEW_MONTH] = 'Month';
    ranges[Calendar.VIEW_MONTH_FROM_NOW] = 'Month from now';

    function modifyDayPerRow(dif:number) {
        setCustomDaysPerRow(Math.min(daysCount,Math.max(1,customDaysPerRow+dif)));
    }

    useEffect(() => {
       showToday();
    },[]);

    function showToday() {
        document.querySelector('.dayTitle.today')?.scrollIntoView({behavior: 'smooth', block: viewType===Calendar.VIEW_WEEK? 'start' : 'end', inline: 'center'});
    }

    return (
        <div id="calendar" className={under960 ? 'jsSticky' : ''}>
            <div id="calendarYM">
                {under960 ? (
                    <div id="daysPerRowSelector">
                        <div className="title">Days<br/>in view</div>
                        <span className="controls" onClick={modifyDayPerRow.bind(undefined, -1)}>-</span>
                        <div className="value" onClick={undefined}>{customDaysPerRow}</div>
                        <span className="controls" onClick={modifyDayPerRow.bind(undefined, 1)}>+</span>
                    </div>) : ''
                }
                <div id="viewSelector">
                    <select value={viewType} onChange={e => setViewType(e.target.value)}>{Object.keys(ranges).map(r => <option value={r}>{ranges[r]}</option>)}</select>
                </div>
                <div id="today" title="Go to Today" onClick={showToday}>today</div>
                <div id="rangeSelector" className={viewType === Calendar.VIEW_MONTH_FROM_NOW ? 'disabled' : ''}>
                    <span className="controls" onClick={prevMonth}>&lt;</span>
                    <div className="value" title="Show this month" onClick={showThisMonth}>{curYear} / {curMonth}</div>
                    <span className="controls" onClick={nextMonth}>&gt;</span>
                </div>
            </div>
            <div id="calendarRows" style={{'--dayCount': daysPerRow} as CSSProperties}>
                {rows.map(((d, i) => <CalendarRow date={d['date']} dayCount={daysCount} events={d['events']} dueTillDone={dueTillDone} inclTimeEstimates={inclTimeEstimates} key={d['date']}/> )) }
            </div>
        </div>
    )
}

Calendar.VIEW_WEEK = 'week';
Calendar.VIEW_MONTH = 'month';
Calendar.VIEW_MONTH_FROM_NOW = 'month-from-now';

export default Calendar;