import React, {useEffect, useRef} from "react";

function HelpMouseMessage({html} : { html : any }){

    const ref = useRef<HTMLDivElement>(null);

    function keepAtMouse(e : any) {
        if (ref.current) {
            ref.current.style.left = Math.min(e.clientX, document.body.clientWidth-250)+'px';
            ref.current.style.top = e.clientY+15+'px';
        }
    }

    useEffect(()=>{
        window.addEventListener('mousemove', keepAtMouse);
        return () => { window.removeEventListener('mousemove', keepAtMouse); }
    },[]);

    return (<div className="mouseMessage" ref={ref}>{html}</div>)
}

export default HelpMouseMessage;
